import React from 'react';
import { useQueryParam } from 'gatsby-query-params';
import SCLandingWrapper from '../components/SCLandingWrapper';

function TX({}) {
  let city = useQueryParam('city', null);
  if (!city) {
    city = 'dallas';
  }

  return <SCLandingWrapper city={city} />;
}

export default TX;
